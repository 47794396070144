<template>
  <div>
    <ion-list v-if="messages.length > 0">
      <ion-item-sliding v-for="message in messages" :key="message">
        <ion-item @click.prevent="updateRoute('/message/' + message.message_id)">
          <div tabindex="0"></div><!-- bug fix for safari 14 -->
          <ion-avatar slot="start">
            <img v-if="message.sender.profile_photo_path == null" src="https://s3-us-west-1.amazonaws.com/artistwavesoftware/users/thumbnails/5f922d6e06874.png">
            <img v-else :src="message.sender.profile_photo_path">
          </ion-avatar>
          <ion-label>
            <h2>{{message.sender.name}} <span class="text-medium">@{{message.sender.username}}</span></h2>
            <h3>RE: {{message.message.subject}}</h3>
            <p>{{message.message.created_at}}</p>
          </ion-label>
        </ion-item>
        <ion-item-options side="end">
          <ion-item-option color="danger" @click.prevent="removeEmail(message.message_id)">
            <span class="margin-left-md margin-right-md">
              <ion-icon :icon="trash" />
            </span>
          </ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
    </ion-list>
    <div v-else>
      <ion-item>
        <div tabindex="0"></div>
        There are no messages
      </ion-item>  
    </div>
  </div>
</template>

<script>
import { loadingController, toastController, IonList, IonItemSliding, IonItemOptions, IonItemOption, IonItem, IonAvatar, IonLabel, IonIcon } from '@ionic/vue';
import { defineComponent, onMounted, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
import apiClient from '../services/api';
import { trash } from 'ionicons/icons';

export default defineComponent({
  props: [],
  name: 'MessageList',
  components: { IonList, IonItemSliding, IonItemOptions, IonItem, IonAvatar, IonLabel, IonIcon, IonItemOption },
  setup() {
    const router = useRouter();
    const store = useStore();
    const authUser = computed(() => store.state.authUser);
    const timeout = { default: 6000 }
    const toastMessage = ref(null);
    const messages = ref([]);

    onMounted(() => {
      getMessages()
    })

    function goBack() {
      router.go(-1)
    }

    async function presentLoading() {
      const loading = await loadingController
      .create({
        cssClass: 'my-custom-class',
        message: 'Please wait...',
        duration: timeout,
      });

      await loading.present();

      setTimeout(function() {
        loading.dismiss()
      }, timeout);
    }

    async function openToast() {
      const toast = await toastController
      .create({
        //message: '',
        duration: 6000,
        message: toastMessage.value,
        position: 'bottom',
        buttons: [
          {
            icon: 'close',
            role: 'cancel',
            handler: () => {

            }
          }
        ]
      })
      return toast.present();
    }

    function getMessages() {  
      presentLoading()

      apiClient.get('/api/get/messages/user_id/'+authUser.value.id,
        {
          headers: {
            'Authorization':  `Bearer ${store.state.authToken}`
          }
        }).then(response => {
          messages.value = response.data
        }).catch(error => {
          console.log(error)
        });         
    }

    function removeEmail(id) {
      presentLoading()
      apiClient.post('/api/hide/message', 
        { 
          user_id: authUser.value.id,
          message_id: id,
        },
        {
          headers: {
            'Authorization':  `Bearer ${store.state.authToken}`
          }
        }).then(response => {
          if(response.data.message == 'success') {
            toastMessage.value = 'You have removed a message.'
            getMessages()
          }
          if(response.data.message == 'fail') {
            toastMessage.value = 'Something went wrong.'
          }
          if(response.data.message == 'fail-validation') {
            toastMessage.value = 'You are not authorized to do that.'
          }
          if(response.data.message == 'fail-auth') {
            toastMessage.value = 'You must be signed in.'
          }
          openToast()
        }).catch(error => {
          console.log(error)
        });
    }

    function updateRoute(val) {
      router.push(val)
    }

    return {
      router, goBack, store, authUser, getMessages, messages, trash, updateRoute, removeEmail
    }
  },
  methods: {

  },
  watch: {

  }
});
</script>

<style scoped>

</style>