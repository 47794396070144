<template>
  <ion-page>
    <!-- Header -->
    <ion-header id="dark-header">
      <ion-toolbar class="text-center">
        <ion-buttons slot="start">
          <ion-button @click.prevent="goBack()">
            <ion-icon :icon="chevronBack"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button @click.prevent="updateRoute('/create-message')">
            <ion-icon :ios="add" :md="add"></ion-icon>
          </ion-button>
        </ion-buttons>

        <ion-title>Messages</ion-title>
      </ion-toolbar>
    </ion-header> 
    <!-- Page Content -->
    <ion-content>
      <!-- Home Feed -->
      <div class="app-max-width">
        <MessageList />
      </div>
    </ion-content>
    <!-- Footer -->
    <ion-footer>
      <Tabs page="messages" />
    </ion-footer>

  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonHeader, IonFooter, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle } from '@ionic/vue';
import { chevronBack, add } from 'ionicons/icons';
import { defineComponent } from 'vue';
import doAuth from '../../services/auth';
import Tabs from '../../components/Tabs.vue';
import { useRouter } from 'vue-router';
import MessageList from '../../components/MessageList.vue';

export default defineComponent({
  name: 'Messages',
  components: {
    IonPage, IonContent, IonHeader, IonFooter, IonToolbar, IonButtons, IonButton, IonIcon, Tabs, MessageList, IonTitle
  },
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
  },
  setup() {
    const router = useRouter();

    function updateRoute(val) {
      router.push(val)
    }

    function goBack() {
      router.go(-1)
    }

    return {
      chevronBack, add, router, updateRoute, goBack
    }
  }
});
</script>

<style scoped>

</style>